import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { ProjectService } from '../../services/Projectservice.service';
import { Observable } from 'rxjs';
import { parse } from 'querystring'
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

import { LocationStrategy,Location } from '@angular/common';
@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.css']
})
export class ConstructionComponent implements OnInit {
  model:any={}
  id = 0;
  client = ""
  apiReturned = true;
  title="Construction Notes";
  lat=0;
  lng=0;
  city="";
  url: SafeResourceUrl
  src="";
  backTitle="";
  nav="";
  constructor(public sanitizer:DomSanitizer,private dataService: DataService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService,private _location:Location,private projectService:ProjectService) {
    if(localStorage.getItem("projectId")=="0"){
      this._location.back();
    }
    this.backTitle=JSON.parse(localStorage.getItem('projectType')).listType;
    this.nav=JSON.parse(localStorage.getItem('projectType')).nav;
    this.id = parseInt(localStorage.getItem("projectId"));
    this.dataService.getProjectData({ Id: this.id })
      .subscribe(
        result => {
          this.client = result.data.ClientName + " / " + result.data.Intersection + " / " + result.data.City;
           this.city= result.data.City;
              this.lat=result.data.Latitude ;
             this.lng=result.data.Longitude ;
          this.src="https://forecast.io/embed/#lat="+this.lat+"&lon="+this.lng+"&name="+this.city+"&color=#00aaff&font=Georgia"
         // this.src="https://forecast.io/embed/#lat=&lon=&name="+this.city+"&color=#00aaff&font=Georgia"
         this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);   
        })
  }


  ngOnInit() {
      
    this.getConstructionVariable();
  }
  back(){
    this.router.navigate([this.nav])
     }
getConstructionVariable(){
  this.projectService.getConstructionVariable({ ProjectId: this.id })
  .subscribe(
    result => {
      if(result.data)
      this.model.DaysPermitSubmittoIssued = result.data.DaysPermitSubmittoIssued;
      this.model.ConstructionDaysElapsed = result.data.ConstructionDaysElapsed;
      this.model.DaysPermitIssuedToConstStart = result.data.DaysPermitIssuedToConstStart;
      this.model.DaysConstStartToOpen = result.data.DaysConstStartToOpen;
      this.model.ConstructionDaysRemaining = result.data.ConstructionDaysRemaining

    })
}
}
