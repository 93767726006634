import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { parse } from 'querystring';
import { LocationStrategy, Location } from '@angular/common';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  model: any = {}
  id = 0;
  client = ""
  apiReturned = true;
  backTitle = "";
  nav = "";
  contactList: any;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private _location: Location, private toastr: ToastrService) {
    if (localStorage.getItem("projectId") == "0") {
      this._location.back();
    }
    this.backTitle = JSON.parse(localStorage.getItem('projectType')).listType;
    this.nav = JSON.parse(localStorage.getItem('projectType')).nav;
    this.id = parseInt(localStorage.getItem("projectId"));
    this.dataService.getProjectData({ Id: this.id })
      .subscribe(
        result => {
          this.client = result.data.ClientName + " / " + result.data.Intersection + " / " + result.data.City;
        })

  }

  ngOnInit() {
    this.model.ToContact1 = 0;
    this.model.ToContact2 = 0;
    this.model.CCContact1 = 0;
    this.model.CCContact2 = 0;
    this.model.FromName = JSON.parse(localStorage.getItem('currentUser')).data.FirstName;
    this.model.FromEmail = JSON.parse(localStorage.getItem('currentUser')).data.Email;
    this.getProjectContacts();
  }
  getProjectContacts() {
    this.dataService.getProjectContactList(this.id)
      .subscribe(
        result => {
          result.data.unshift({ Id: 0, Name: "Select" })
          this.contactList = result.data;

        })
  }
  loadContact(){
    this.getProjectContacts();
  }
  sendEmail() {
    // if (!this.model.FromName) {
    //   this.toastr.error('', 'Please enter name.');
    //   return
    // }
    if (!this.model.EmailSubject) {
      this.toastr.error('', 'Please enter subject.');
      return
    }
    if (!this.model.FromEmail) {
      this.toastr.error('', 'Please enter From Email.');
      return
    }
    else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.model.FromEmail.trim())) {
        this.toastr.error('', 'Please enter valid email.');
        return
      }
    }
    if (this.model.ToContact1 <= 0) {
      this.toastr.error('', 'Please select contact 1.');
      return
    }
    if (!this.model.BodyText) {
      this.toastr.error('', 'Please enter body');
      return
    }
    this.model.ProjectID = this.id;
    this.model.FromEmail = this.model.FromEmail.trim();
    this.model.UpdateBy = JSON.parse(localStorage.getItem('currentUser')).data.Id;
    this.dataService.sendMailToProjectContact(this.model)
      .subscribe(
        result => {
          if (result.messageCode == 2) {
            this.toastr.error('', result.message);
           
          } else {
           
            this.model.ToContact1 = 0;
            this.model.ToContact2 = 0;
            this.model.CCContact1 = 0;
            this.model.CCContact2 = 0;
            this.model.EmailSubject = "";
            this.model.BodyText = "";
            this.toastr.success('', result.message);
          }
        });
  }

  back() {
    this.router.navigate([this.nav])
  }
}
