import { Injectable,Output ,EventEmitter} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map'
import { map } from 'rxjs/operators';
import {AppSettings} from '../app-Settings';
let ApiUrl = AppSettings.API_ENDPOINT;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
};
//import { HttpResponse } from 'selenium-webdriver/http';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient, private route: ActivatedRoute,
    private router: Router) { }
  login(username: string, password: string) {
  
    return this.http.post<any>(ApiUrl + 'User/login', { UserName: username, Password: password })
      .map(user => {
      //  console.log("username", user)
        if (user) {
          this.getLoggedInName.emit(user.data);
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      });
    //  this.http.post<any>(ApiUrl + 'User/login', { UserName: username, Password: password },httpOptions).subscribe(data => {
    //   console.log("username",data)
    //   if(data.messageCode==1){
    //     localStorage.setItem('isLoggedin', 'true');
    //     this.router.navigate(['']);
    //   }

    //  });
  }
  forgetPassword(param) {
   
    return this.http.post<any>(ApiUrl + 'User/getPassword', param)
      .map(user => {
        return user;
      });
  }
  getUserAccessList(param) {
   
    return this.http.post<any>(ApiUrl + 'User/getUserAccessList', param)
      .map(user => {
        return user;
      });
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }
}
