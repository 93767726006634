import { Component, OnInit } from '@angular/core';
import { ServerDataSource } from 'ng2-smart-table';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../../services/dataService.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ViewlinkComponent } from '../../components/viewlink/viewlink.component';
import { AppSettings } from '../../app-Settings';
let ApiUrl = AppSettings.API_ENDPOINT;
@Component({
  selector: 'app-projectlist',
  templateUrl: './projectlist.component.html',
  styleUrls: ['./projectlist.component.css']
})
export class ProjectlistComponent implements OnInit {
  users: any;
  settings: any;
  data: ServerDataSource
  projectList: any
  ptype = 0;
  constructor(private http: HttpClient, private dataService: DataService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) {

    this.route.params.subscribe(params => {
      if (params['ptype']) {
        this.ptype = params['ptype']
        if (this.ptype != 0) {
          this.data = new ServerDataSource(http, {
            //endPoint: 'http://localhost:51498/api/SiteProfile/getProjectDataList',
            endPoint: ApiUrl + 'SiteProfile/getProjectDataList?ProjectType=' + this.ptype,
            // token: JSON.parse(localStorage.getItem('currentUser')).response.accessToken,
            dataKey: 'data.data',
            totalKey: 'TotalCount',
            pagerLimitKey: 'RecordCount',
            pagerPageKey: 'PageNumber',
            filterFieldKey: '#field#',
            sortFieldKey: 'SortColumn',
            sortDirKey: 'SortOrder',
          });

        }

      }
    });



    this.settings = {
      columns: {

        Client: {
          title: 'Client',
          type: 'custom',
          // renderComponent: CustomRendererComponent,
          renderComponent: ViewlinkComponent
        },
        City: {
          title: 'City'
        },
        State: {
          title: 'State'
        },
        Intersection: {
          title: 'Intersection'
        },
        DealType: {
          title: 'Deal Type',
          filter: false
        },
        Project_Status: {
          title: 'Status',
          filter: false
        },
      },

      actions: {
        edit: false,
        add: false,
        delete: false,
        // custom: [
        //   { name: 'Edit', title: `<i  class="fa fa-edit"></i>` }

        // ],
        position: 'left', // left|right
      },
      pager: {
        display: true,
        perPage: 300
      },
      attr: {
        class: 'table table color-table dark-table table-striped',
      },

    };

  }

  ngOnInit() {
    //this.bindProjectList();
   
   // localStorage.setItem("projectId", "0");
  }
  newProject() {
    var previousList={};
    if(this.ptype==1){
      previousList={
        listType:"Current Projects",
        nav:"projects/1"
      }
    }else if(this.ptype==2){
      previousList={
        listType:"Complete Projects",
        nav:"completeprojects/2"
      }
    }else{
      previousList={
        listType:"Dead Projects",
        nav:"deadprojects/3"
      }
    }
 
    localStorage.setItem("projectType",  JSON.stringify(previousList));

    localStorage.setItem("projectId", "0");
    this.router.navigate(['/projectdetail'])
    
  }

  bindProjectList() {

    this.dataService.getProjectDataList()
      .subscribe(
        result => {
          this.projectList = result.data;
        })
  }
  
}
