import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isLoggedIn: boolean
  currentYear: number = new Date().getFullYear();
  constructor(private router: Router) { }

  ngOnInit() {
  }
  isValid(): boolean {
    //console.log("PAGE", this.router.url);
    if (this.router.url == "/login") {
    
      return false
    }
    return true
  }
}
