import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
import { parse } from 'querystring';

import { LocationStrategy,Location } from '@angular/common';
@Component({
  selector: 'app-entitlement',
  templateUrl: './entitlement.component.html',
  styleUrls: ['./entitlement.component.css']
})
export class EntitlementComponent implements OnInit {

  id = 0;
  client = ""
  apiReturned = true;
  title="Entitlement Notes";
  backTitle="";
  nav="";
  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService,private _location:Location) {
    if(localStorage.getItem("projectId")=="0"){
      this._location.back();
    }
    this.backTitle=JSON.parse(localStorage.getItem('projectType')).listType;
    this.nav=JSON.parse(localStorage.getItem('projectType')).nav;
    this.id = parseInt(localStorage.getItem("projectId"));
    this.dataService.getProjectData({ Id: this.id })
      .subscribe(
        result => {
          this.client = result.data.ClientName + " / " + result.data.Intersection + " / " + result.data.City;
        })




  }

  ngOnInit() {
  }
  back(){
    this.router.navigate([this.nav])
     }
}
