import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/dataService.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-projectdetail',
  templateUrl: './projectdetail.component.html',
  styleUrls: ['./projectdetail.component.css']
})
export class ProjectdetailComponent implements OnInit {
  id = "0";
  client=""
  apiReturned = true;
  title="Deal Notes";
  backTitle="";
  nav="";
  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {
    //this.route.params.subscribe(params => {
    //  if (params['id']) {
        this.id = localStorage.getItem("projectId")
        this.backTitle=JSON.parse(localStorage.getItem('projectType')).listType;
        this.nav=JSON.parse(localStorage.getItem('projectType')).nav;

      //  localStorage.setItem("projectId",this.id.toString());
        if (this.id != "0") {
          this.apiReturned = false
          this.dataService.getProjectData({ Id: this.id })
            .subscribe(
              result => {
            
                this.client = result.data.ClientName+" / "+ result.data.Intersection+" / "+ result.data.City;
              })
        }

    
    //});
  }
  
  ngOnInit() {

  }
  onClose(){
    this.apiReturned = false
   
  }
  back(){
    this.router.navigate([this.nav])
  }
}
